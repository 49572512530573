import Provider from '@/provider'
import {
  Attribute,
  CheckoutAttributesUpdateInput,
  CheckoutDiscountCodeApplyInput,
  CheckoutDiscountCodeRemoveInput,
  CheckoutEmailUpdateInput,
  CheckoutLineItemInput,
  CheckoutLineItemsAddInput,
  CheckoutLineItemsRemoveInput,
  CheckoutLineItemsUpdateInput,
  CheckoutLineItemUpdateInput,
  CreateCheckoutInput,
} from '@/provider/type'
import Analytics from '@/services/analytics'
import CacheService from '@/services/cache'
import {
  Checkout,
  CheckoutLineItem,
  InputWithTimeStamp,
  NullOrType,
} from '@/types'
import {
  CACHE_KEY_CHECKOUT_ID,
  COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY,
  DISCOUNT_MESSAGE_LINE_ITEM_ATTRIBUTE_KEY,
  DOMAIN_ATTRIBUTE_KEY,
  PRICE_LINE_ITEM_ATTRIBUT_KEY,
  USER_TRAITS_ATTRIBUTE_KEY,
  UTMS_ATTRIBUTE_KEY,
  FIRST_PAGE_KEY,
  COOKIE_KEY_ATTRIBUTE_UTM_PREFIX,
  ONE_YEAR_IN_SECONDS,
} from '@/utils'
import {
  extractProductSKUFromHandle,
  getTruncatedVariantSKU,
  getTruncatedVariantSKUWithProductHandlle,
} from '@/utils/product'
import { isOnServer } from '@/utils/ssr'
import { defineStore } from 'pinia'
import { CookieManager } from '@/services/cookie'
import useSettingStore from '../setting'

export interface CheckoutState {
  checkout: NullOrType<Checkout>
  localCartLineItems: CheckoutLineItem[]
  lastUpdateTimeStamp: number
  recoveryCheckoutId: NullOrType<string>
  shouldRecoveryCheckoutIdBaseOnEmailKlaviyo: boolean
  recoverCheckoutIdByEmailMessage: string
}

const useCheckoutStore = defineStore('checkout', {
  state: (): CheckoutState => ({
    checkout: null,
    localCartLineItems: [],
    lastUpdateTimeStamp: 0,
    recoveryCheckoutId: null,
    shouldRecoveryCheckoutIdBaseOnEmailKlaviyo: false,
    recoverCheckoutIdByEmailMessage: '',
  }),
  getters: {
    subTotal: (state) => {
      return state.checkout?.subtotalPriceV2?.amount || 0
    },
    totalLineItemsPrice: (state) => {
      let total = 0
      state.checkout?.lineItems.forEach((item) => {
        total += item.quantity * (item.compareAtPrice || item.price || 0)
      })
      return total
    },
    totalDiscount(): number {
      return this.totalLineItemsPrice - this.subTotal
    },
    totalCartItems: (state) => {
      return state.localCartLineItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      )
    },
    discountCodeCurrentApply(state) {
      return state?.checkout?.discountApplications.length
        ? state.checkout.discountApplications[0].code
        : ''
    },
  },
  actions: {
    async getCurrentCheckout() {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      let checkoutId = ''
      if (this.recoveryCheckoutId) {
        checkoutId = this.recoveryCheckoutId
        this.recoveryCheckoutId = null
      } else if (this.checkout) {
        checkoutId = this.checkout.id
      } else {
        const cookier = new CookieManager()
        // Use this variable for this purpose migrate data checkout to cart
        const checkoutIdFromCookie = ''

        // const checkoutIdFromCookie = cookier.getCookie(CACHE_KEY_CHECKOUT_ID)
        if (checkoutIdFromCookie) {
          checkoutId = checkoutIdFromCookie
        } else {
          const checkoutIdFromCache = await CacheService.instance?.get(
            CACHE_KEY_CHECKOUT_ID
          )
          if (checkoutIdFromCache && checkoutIdFromCache.value) {
            checkoutId = checkoutIdFromCache.value
            const cookier = new CookieManager()

            const href = new URL(window.location.href)
            const currentDomain = href.hostname

            // Hide this logic for this purpose migrate data checkout to cart
            // cookier.setCookie(CACHE_KEY_CHECKOUT_ID, checkoutId, {
            //   maxage: ONE_YEAR_IN_SECONDS,
            //   domain: '.' + currentDomain,
            //   path: '/',
            // })
          }
        }
      }
      if (checkoutId) {
        return provider.getCheckout(checkoutId)
      }
      return null
    },
    async createCheckout(input: CreateCheckoutInput) {
      if (isOnServer) return

      input.customAttributes = await fillUpCheckoutAttributes(
        input.customAttributes
      )

      const provider = await Provider.getInstance()
      return provider.createCheckout(input)
    },

    async saveCheckoutAndLineItems(checkout: NullOrType<Checkout>) {
      if (isOnServer) return
      this.saveCheckout(JSON.parse(JSON.stringify(checkout)))
      this.localCartLineItems = JSON.parse(
        JSON.stringify(checkout?.lineItems || [])
      )
      this.localCartLineItems = this.localCartLineItems.map(fillUpLineItem)
    },
    async saveCheckout(checkout: NullOrType<Checkout>) {
      if (isOnServer) return
      this.checkout = checkout
      if (!this.checkout) return
      this.checkout.lineItems = this.checkout.lineItems.map(fillUpLineItem)
      const checkoutId = checkout?.id
      if (checkoutId) {
        // cache checkout forever
        CacheService.instance?.set(CACHE_KEY_CHECKOUT_ID, checkoutId, -1)

        // cache checkout id in cookie fallback brower doesn't support IndexDB and sync cart online store with shopify theme
        const href = new URL(window.location.href)
        const currentDomain = href.hostname
        const cookier = new CookieManager()
        cookier.setCookie(CACHE_KEY_CHECKOUT_ID, checkoutId, {
          maxage: ONE_YEAR_IN_SECONDS,
          domain: '.' + currentDomain,
          path: '/',
        })
      } else {
        CacheService.instance?.delete(CACHE_KEY_CHECKOUT_ID)
      }
    },
    async saveCheckoutWithoutUpdateLineItemsQuantity(
      checkout: NullOrType<Checkout>
    ) {
      if (isOnServer) return
      if (!checkout) return
      this.saveCheckout(checkout)

      // use the lineItems from the new checkout but reserve the quantity from the old checkout
      let lineItems = JSON.parse(JSON.stringify(checkout.lineItems || []))
      lineItems = lineItems.map((lineItem: CheckoutLineItem) => {
        const oldLineItem = this.localCartLineItems.find(
          (item) => item.id === lineItem.id
        )
        if (oldLineItem) {
          lineItem.quantity = oldLineItem.quantity
        }
        lineItem = fillUpLineItem(lineItem)
        return lineItem
      })
      this.localCartLineItems = lineItems
    },
    async addItemToCheckout(input: CheckoutLineItemsAddInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.checkoutLineItemsAdd(input)
    },
    async removeItemFromCheckout(input: CheckoutLineItemsRemoveInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.checkoutLineItemsRemove(input)
    },

    async updateItemsInCheckout(input: CheckoutLineItemsUpdateInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.checkoutLineItemsUpdate(input)
    },

    async updateCheckoutCustomAttributes(input: CheckoutAttributesUpdateInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.checkoutAttributesUpdate(input)
    },

    async checkoutDiscountCodeApply(discountCode: string) {
      if (isOnServer) return
      const checkoutId = this.checkout?.id
      if (!checkoutId) return
      const provider = await Provider.getInstance()
      const input: CheckoutDiscountCodeApplyInput = {
        checkoutId,
        discountCode,
      }
      const response = await provider.checkoutDiscountCodeApply(input)
      this.saveCheckoutAndLineItems(response.checkout)
      return response
    },

    async checkoutDiscountCodeRemove() {
      if (isOnServer) return
      const checkoutId = this.checkout?.id
      if (!checkoutId) return
      const provider = await Provider.getInstance()
      const input: CheckoutDiscountCodeRemoveInput = {
        checkoutId,
      }
      const checkout = await provider.checkoutDiscountCodeRemove(input)
      this.saveCheckoutAndLineItems(checkout)
    },

    async checkoutEmailUpdate(email: string) {
      if (isOnServer) return
      const checkoutId = this.checkout?.id
      if (!checkoutId) return
      const provider = await Provider.getInstance()
      const input: CheckoutEmailUpdateInput = {
        checkoutId,
        email,
      }
      return provider.checkoutEmailUpdate(input)
    },

    async updateCheckoutShippingAddress(input: {
      checkoutId: string
      shippingAddress: MailingAddressInput
    }) {
      if (isOnServer) return
      if (!input.checkoutId || !input.shippingAddress) return
      const provider = await Provider.getInstance()
      return provider.updateCheckoutShippingAddress(input)
    },

    async updateCheckoutEmail(input: { checkoutId: string; email: string }) {
      if (isOnServer) return
      if (!input.checkoutId || !input.email) return
      const provider = await Provider.getInstance()
      return provider.checkoutEmailUpdate(input)
    },

    async addToCart(lineItem: CheckoutLineItemInput) {
      if (isOnServer) return
      if (!lineItem.variantId || !lineItem.quantity) return

      if (!this.checkout) {
        const checkout = await this.createCheckout({
          lineItems: [lineItem],
        })
        this.saveCheckoutAndLineItems(checkout)
        return checkout
      }
      const checkout = await this.addItemToCheckout({
        checkoutId: this.checkout.id,
        lineItems: [lineItem],
      })
      this.saveCheckoutAndLineItems(checkout)
      return checkout
    },

    async removeFromCart(
      input: InputWithTimeStamp<{
        lineItemId: string
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.checkout || !input.lineItemId) return
      const checkout = await this.removeItemFromCheckout({
        checkoutId: this.checkout.id,
        lineItemIds: [input.lineItemId],
      })
      // only save the checkout of the lastest request
      if (this.lastUpdateTimeStamp !== input.timeStamp) return null
      this.saveCheckoutAndLineItems(checkout)
      return checkout
    },

    async updateCartLineItem(
      input: InputWithTimeStamp<{
        id: string
        quantity: number
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.checkout || !input.id) return
      const checkout = await this.updateItemsInCheckout({
        checkoutId: this.checkout.id,
        lineItems: [
          {
            id: input.id,
            quantity: input.quantity,
          },
        ],
      })
      // only save the checkout of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCheckoutAndLineItems(checkout)
        return checkout
      }
      return null
    },

    async updateAttributeCartLineItem(
      input: InputWithTimeStamp<{
        id: string
        customAttributes: { key: string; value: string }[]
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.checkout || !input.id) return
      const checkout = await this.updateItemsInCheckout({
        checkoutId: this.checkout.id,
        lineItems: [
          {
            id: input.id,
            customAttributes: input.customAttributes,
          },
        ],
      })
      // only save the checkout of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCheckoutAndLineItems(checkout)
        return checkout
      }
      return null
    },

    async updateCartLineItems(
      input: InputWithTimeStamp<{
        lineItems: CheckoutLineItemUpdateInput[]
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.checkout) return
      const checkout = await this.updateItemsInCheckout({
        checkoutId: this.checkout.id,
        lineItems: input.lineItems,
      })
      // only save the checkout of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCheckoutWithoutUpdateLineItemsQuantity(checkout)
        return checkout
      }
      return null
    },
  },
})

export async function fillUpCheckoutAttributes(attributesInput?: Attribute[]) {
  const attributes: Attribute[] = []
  if (attributesInput) {
    attributesInput.forEach((attribute) => {
      attributes.push({
        key: attribute.key,
        value: attribute.value,
      })
    })
  }
  const domainAttributeIndex = attributes.findIndex(
    (attribute) => attribute.key == DOMAIN_ATTRIBUTE_KEY
  )
  if (domainAttributeIndex === -1) {
    attributes.push({
      key: DOMAIN_ATTRIBUTE_KEY,
      value: window.location.origin,
    })
  }

  const userTraitsAttribute = Analytics.getUserTraits()

  if (userTraitsAttribute) {
    const userTraitsAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == USER_TRAITS_ATTRIBUTE_KEY
    )
    if (userTraitsAttributeIndex > -1) {
      attributes.splice(userTraitsAttributeIndex, 1)
    }
    attributes.push({
      key: USER_TRAITS_ATTRIBUTE_KEY,
      value: JSON.stringify(userTraitsAttribute),
    })
  }

  const firstPageFromCookie = Analytics.getCookieFirstPage()
  if (firstPageFromCookie) {
    const firstPageAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == FIRST_PAGE_KEY
    )
    if (firstPageAttributeIndex > -1) {
      attributes.splice(firstPageAttributeIndex, 1)
    }
    attributes.push({
      key: FIRST_PAGE_KEY,
      value: firstPageFromCookie,
    })
  }

  const currentUserUtms = await Analytics.getCurrentUTMs()
  if (currentUserUtms) {
    const utmsAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == UTMS_ATTRIBUTE_KEY
    )
    if (utmsAttributeIndex > -1) {
      attributes.splice(utmsAttributeIndex, 1)
    }
    attributes.push({
      key: UTMS_ATTRIBUTE_KEY,
      value: JSON.stringify(currentUserUtms),
    })
  }

  const cookieUserUtms = Analytics.getCookieCurrentUTMs()
  if (cookieUserUtms) {
    const UTM_PARAM_PREFIX = 'utm_'
    Object.keys(cookieUserUtms).forEach((key) => {
      const utmAttributeKey = `${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}${UTM_PARAM_PREFIX}${key}`
      const utmsAttributeIndex = attributes.findIndex(
        (attribute) => attribute.key == utmAttributeKey
      )
      if (utmsAttributeIndex > -1) {
        attributes.splice(utmsAttributeIndex, 1)
      }
      attributes.push({
        key: utmAttributeKey,
        value: cookieUserUtms[key],
      })
    })
  }
  return attributes
}

function fillUpLineItem(lineItem: CheckoutLineItem) {
  const priceAttribute = lineItem.customAttributes.find(
    (elm) => elm.key == PRICE_LINE_ITEM_ATTRIBUT_KEY
  )
  const isPriceAttributeValid =
    priceAttribute && priceAttribute.value && !isNaN(+priceAttribute.value)
  const priceFromAttribute = isPriceAttributeValid
    ? +priceAttribute.value / 100
    : 0

  const compareAtPriceAttribute = lineItem.customAttributes.find(
    (elm) => elm.key == COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY
  )
  const isCompareAtPriceAttributeValid =
    compareAtPriceAttribute &&
    compareAtPriceAttribute.value &&
    !isNaN(+compareAtPriceAttribute.value)
  const compareAtPriceFromAttribute = isCompareAtPriceAttributeValid
    ? +compareAtPriceAttribute.value / 100
    : 0

  const discountMessageAttribute = lineItem.customAttributes.find(
    (elm) => elm.key == DISCOUNT_MESSAGE_LINE_ITEM_ATTRIBUTE_KEY
  )

  lineItem.price = isPriceAttributeValid
    ? priceFromAttribute
    : lineItem.variant?.price || 0
  lineItem.compareAtPrice = isCompareAtPriceAttributeValid
    ? compareAtPriceFromAttribute
    : lineItem.variant?.compareAtPrice || 0
  if (discountMessageAttribute) {
    lineItem.discountMessage = discountMessageAttribute.value
  }

  if (lineItem.variant && lineItem.variant.sku) {
    const settingStore = useSettingStore()
    let hasProductHandle = false
    if (lineItem.variant.product?.handle) {
      const skuFromHandle = extractProductSKUFromHandle(
        lineItem.variant.product?.handle
      )
      hasProductHandle =
        settingStore.shop?.googleProductSKUsFromHandle
          ?.split(',')
          .includes(skuFromHandle) || false
    }
    lineItem.variant.sku = hasProductHandle
      ? getTruncatedVariantSKU(lineItem.variant.sku)
      : getTruncatedVariantSKUWithProductHandlle(
          lineItem.variant.sku,
          lineItem.variant.product?.handle
        )
  }

  // Analytics error if variant is null to track this bug
  if (!lineItem.variant) {
    try {
      throw lineItem.variant.price
    } catch (err: any) {
      Analytics.error(err)
    }
  }

  return lineItem
}

export default useCheckoutStore
