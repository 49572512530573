<template>
  <div class="cart__price">
    <!-- <CartDiscountCode v-if="cartStore.totalCartItems" /> -->
    <CartEmailForSaveItems v-if="cartStore.totalCartItems" />

    <CartShippingFeeProgressBar
      v-if="cartStore.totalCartItems && freeShippingLimit"
    />
    <!-- <CartProductUpsell
      v-if="!props.isFullPage && !deviceStore.isMobile"
      :lineItems="cartLineItems"
      class="mb-sm"
    /> -->

    <div class="divider my-sm"></div>

    <div class="cart__price-item">
      <div class="item__label">
        <span>Price</span>
        <span class="item__label--small"></span>
      </div>
      <div class="item__price">
        {{ formatPrice(cartStore.totalLineItemsPrice) }}
      </div>
    </div>
    <div class="cart__price-item">
      <div class="item__label">
        <span class="item__label--main">Discount</span>
        <span class="item__label--small" v-show="cartStore.totalDiscount">
          We've chosen the best valuable discount for you
        </span>
      </div>
      <div class="item__price price--discounted">
        <template v-if="!isPriceEqualToZero(+cartStore.totalDiscount)"
          >-</template
        >{{ formatPrice(cartStore.totalDiscount) }}
      </div>
    </div>
  </div>
  <template v-if="discountStore.discountProgress">
    <DiscountProgressBarComponent></DiscountProgressBarComponent>
  </template>

  <CartSubtotalContainer :isFullPage="isFullPage">
    <div class="divider"></div>
    <div ref="cartSubtotalSticker"></div>
    <div
      class="cart__subtotal"
      :class="{ 'cart__subtotal--sticky': shouldStickSubtotal }"
    >
      <div class="cart__price-item mt-sm">
        <div class="item__label">
          <span class="item__label--main"
            >Subtotal
            <small v-if="cartStore.totalDiscount"
              >(You saved {{ formatPrice(cartStore.totalDiscount) }})</small
            ></span
          >
          <span class="item__label--small">
            Shipping fee is calculated at checkout
          </span>
        </div>
        <div class="item__price">
          {{ formatPrice(cartStore.subTotal) }}
        </div>
      </div>
      <div class="cart__buttons mb-sm">
        <sib-button
          v-if="
            isGreetingCardFlow &&
            isGreetingCardChoosed &&
            !isProductTypeGreetingCardInLineItems
          "
          class="cart__button"
          :size="deviceStore.isMobile ? 'xl' : isFullPage ? 'xl' : 'md'"
          width="full"
          role="primary"
          @click="goToGreetingCardCollection"
          :loading="loading"
        >
          Go to Greeting Card Collection
        </sib-button>

        <sib-button
          :disabled="!cartStore.cart?.checkoutUrl || !cartStore.totalCartItems"
          class="cart__button"
          :size="deviceStore.isMobile ? 'xl' : isFullPage ? 'xl' : 'md'"
          width="full"
          role="primary"
          @click="goToCheckout"
          :loading="loading"
        >
          Proceed to Checkout
        </sib-button>
      </div>
    </div>
    <div class="cart__trust-container">
      <img
        class="cart__trust-image"
        loading="lazy"
        src="@/assets/images/trust-badge.webp"
        alt="trust bagde"
        width="686"
        height="46"
      />
      <div class="cart__trust-item">
        Exchange or Refund for faulty products. No question asked.
      </div>
      <div class="cart__trust-item mb-md">
        Full or Partial Refund if the items is not as described.
      </div>
      <sib-link
        :to="'/cart'"
        title="Shopping Cart"
        class="cart__button cart__go-to-cart sib-button sib-button--width-full"
        @click="goToCartPage"
      >
        View Cart Page
      </sib-link>
      <div class="cart__continue-shopping text--center">
        <a class="color--primary" @click="$emit('link-clicked')">
          Continue Shopping
        </a>
        <sib-link title="" to="/" class="color--primary">
          Continue Shopping
        </sib-link>
      </div>
    </div>
  </CartSubtotalContainer>
</template>

<script lang="ts">
import useDeviceStore from '@/store/device'
import Analytics from '@/services/analytics'
import {
  CURRENCY,
  EVENT_BUY_NOW,
  EVENT_TOGGLE_CART_ITEM_PERSONALIZATION,
  EVENT_TRACKING_GO_TO_CHECKOUT,
} from '@/utils'
import EventBus from '@/services/eventbus'
import Logger from '@/services/log'

export const CartSubtotalContainer = defineComponent({
  props: {
    isFullPage: Boolean,
  },
  setup(props, { slots }) {
    const deviceStore = useDeviceStore()
    return () => {
      if (!props.isFullPage && !deviceStore.isMobile) {
        return h('div', { class: 'cart__subtotal-container' }, slots.default!())
      }
      return h(() => slots.default!())
    }
  },
})
</script>

<script lang="ts" setup>
import {
  defineComponent,
  defineAsyncComponent,
  h,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch,
  computed,
} from 'vue'
import useCartStore from '@/store/cart'
import useDiscountStore from '@/store/discount'
import {
  formatPrice,
  standardizeCartIdFromShopifyId,
  isPriceEqualToZero,
  isProductHasType,
} from '@/utils/product'
import { getCurrentTimeStamp } from '@/utils'
import { useRouter } from 'vue-router'
import useMediaCampaignStore from '@/store/media'
import CartEmailForSaveItems from './CartEmailForSaveItems.vue'
import { useQrGreetingCardAndDigitalContent } from '@/pages/product/composables/useQrGreetingCardAndDigitalContent'
import useSettingStore from '@/store/setting'

const DiscountProgressBarComponent = defineAsyncComponent(
  () => import('@/components/DiscountProgressBar.vue')
)

const CartShippingFeeProgressBar = defineAsyncComponent(
  () => import('./CartShippingFeeProgressBar.vue')
)

const { checkQrGreetingaCardAndDigitalContent } =
  useQrGreetingCardAndDigitalContent()
const mediaCampaignStore = useMediaCampaignStore()
const router = useRouter()
const deviceStore = useDeviceStore()
const discountStore = useDiscountStore()
const settingStore = useSettingStore()

const props = withDefaults(
  defineProps<{
    wrapper: HTMLElement | null
    isUseWrapper: boolean
    isFullPage: boolean
    itemLoading: boolean
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'link-clicked'): void
}>()

const cartStore = useCartStore()
const shouldStickSubtotal: Ref<boolean> = ref(false)
const cartSubtotalSticker: Ref<HTMLElement | null> = ref(null)
const loading = ref(false)

const isGreetingCardFlow = computed(() => mediaCampaignStore.greetingCardFlow)

const isGreetingCardChoosed = computed(
  () => mediaCampaignStore.greetingCardChoosed
)

const isProductTypeGreetingCardInLineItems = computed(() => {
  const index = cartStore.localCartLineItems.findIndex((lineItem) =>
    isProductHasType(lineItem?.variant?.product, ['Greeting Card'])
  )
  return index != -1
})

const freeShippingLimit = computed(() => settingStore.shop?.freeShippingLimit)

onMounted(() => {
  if (deviceStore.isMobile) {
    // watching scroll event on mobile only to toggle sticky element
    if (props.isUseWrapper) {
      watch(
        () => props.wrapper,
        (value) => {
          if (!cartSubtotalSticker.value) return
          if (value) {
            value.addEventListener('scroll', onScroll, {
              passive: true,
            })
          }
        },
        { immediate: true }
      )
    } else {
      window.addEventListener('scroll', onScroll, {
        passive: true,
      })
    }

    // handle to sticky the proceed to cart button when the cart has many items first loading
    watch(
      [() => cartStore.totalCartItems, () => props.itemLoading],
      () => {
        if (!deviceStore.isMobile) return
        // wait a tick for the template update (from loading state to showing cart items)
        setTimeout(onScroll)
      },
      { immediate: true }
    )

    // re-calculate scroll position whenever toggle personalization because it change the container height
    EventBus.on(
      EVENT_TOGGLE_CART_ITEM_PERSONALIZATION,
      onToggleCartItemPersonalization
    )
  }

  // show loading state on buy now event
  EventBus.on(EVENT_BUY_NOW, onBuyNow)
  window.addEventListener('pageshow', onPageShow)
})

onUnmounted(() => {
  if (!deviceStore.isMobile) return
  if (props.wrapper && props.isUseWrapper) {
    props.wrapper.removeEventListener('scroll', onScroll)
  } else {
    window.removeEventListener('scroll', onScroll)
  }
  EventBus.off(
    EVENT_TOGGLE_CART_ITEM_PERSONALIZATION,
    onToggleCartItemPersonalization
  )

  EventBus.off(EVENT_BUY_NOW, onBuyNow)

  window.removeEventListener('pageshow', onPageShow)
})

function updateCart(quantity: number, id: string) {
  const timeStamp = getCurrentTimeStamp()
  return cartStore.updateCartLine({ id, quantity, timeStamp })
}

function onPageShow(event: PageTransitionEvent) {
  // when the page is restore from bfcache (click back button)
  // clear all loading state
  if (event.persisted) {
    loading.value = false
    Logger.log('Cart Price', 'Restore page from bfcache', 2)
  }
}

function onBuyNow() {
  loading.value = true
}

function onToggleCartItemPersonalization() {
  // wait a tick for the UI update
  setTimeout(onScroll)
}

async function goToCheckout() {
  loading.value = true

  const lineItemsQrGreetingCardAndDigitalContent =
    checkQrGreetingaCardAndDigitalContent()

  if (lineItemsQrGreetingCardAndDigitalContent.length) {
    const lineItemsNeedRemove = lineItemsQrGreetingCardAndDigitalContent.filter(
      (item) => item.isDelete
    )
    if (lineItemsNeedRemove.length) {
      for (let i = 0; i < lineItemsNeedRemove.length; i++) {
        await updateCart(0, lineItemsNeedRemove[i].lineItemId)
      }
    }
  }
  // Go to checkout after tracking complete or after a fixed timeout
  setTimeout(redirectToCheckout, 1000)

  function redirectToCheckout() {
    if (cartStore.cart?.checkoutUrl) {
      window.location.href = cartStore.cart?.checkoutUrl
    }
  }

  // tracking
  const products: any[] = []
  cartStore.localCartLineItems.forEach((lineItem) => {
    const product = lineItem.merchandise?.product
    if (!product) return
    products.push({
      product_id: product.id,
      quantity: lineItem.quantity,
      name: product.title,
      handle: product.handle,
      url: window.location.href,
      price: lineItem.price,
      customAttributes: JSON.stringify(lineItem.attributes),
      image_url: product.featuredImage?.src,
      type: product.productType,
      tags: product.tags,
      collections: product.collections?.map((collection) => collection.title),
    })
  })
  Analytics.track(
    EVENT_TRACKING_GO_TO_CHECKOUT,
    {
      cart_id: standardizeCartIdFromShopifyId(cartStore.cart?.id),
      currency: CURRENCY,
      products,
    },
    redirectToCheckout
  )
}

function goToCartPage() {
  emit('link-clicked')
}

function onScroll() {
  // threshhold to make the button stick sooner
  // when the sticker above the bottom of the window a distance equal the threshhold the button should start sticking
  const threshhold = 100
  if (!cartSubtotalSticker.value) {
    return
  }
  // Check if element is under the view port
  const rect = cartSubtotalSticker.value.getBoundingClientRect()
  if (rect.top - window.innerHeight + threshhold < 0) {
    shouldStickSubtotal.value = false
  } else {
    shouldStickSubtotal.value = true
  }
}

function goToGreetingCardCollection() {
  emit('link-clicked')
  router.push(`/collections/greeting-cards`)
}
</script>

<style lang="scss">
.cart {
  $S: &;

  &__price-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    margin-bottom: var(--space-small);
    font-size: 16px;
    line-height: 24px;

    .item {
      &__label {
        display: flex;
        flex-direction: column;
        gap: 0 8px;

        &--small {
          font-size: 12px;
          color: var(--text-color-tertiary);
          line-height: 16px;
        }
      }
    }

    small {
      display: none;
    }
  }

  &__button {
    font-size: 1em;
    font-weight: 700;
  }

  &__subtotal {
    background-color: #fff;
    overflow: hidden;

    &--sticky {
      position: sticky;
      background-color: var(--background-color);
      bottom: 0;
      border-radius: 24px 24px 0 0;
      animation: slide-up 0.3s;
      margin: -1em;
      padding: 0 1em;

      .cart__price-item small {
        display: inline;
      }
    }
  }

  &__trust-container {
    padding-bottom: 2em;
  }

  &__trust-item {
    padding-left: 3em;
    font-size: 10px;
    font-weight: 600;
    position: relative;
    margin-top: var(--space-small);

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: solid 1px var(--color-primary);
      border-radius: 4px;
    }

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: -3px;
      left: 4px;
      width: 20px;
      height: 20px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH5SURBVHgBzZRLTBNRFIb/e+80mgjVrRujiZr42BnShrioC2zBrYkbTVy5MNFATQg7IDEuSGgpaly40UTRdK8CNaJIm+GxgLBhQ3h0TwiwgDJzD3eGPnh3btsFX9LOnDtn/vvPPycD1JjQSJdROOeoIYHh6GOZW7tYqA3UgJvJ53X1/rPtjOwfo+FEtrBetfPgr9bL/vNnMiRozgwnxvdeq0rcEYbgIyB8n2iKDxy8XrF4w2DbLQjxXwl/NiPxN0f1MFRAMNV6g8BHGdgfs6n30XF92i/UjYL4ICNscGuz/aReLfFixsAlbm9fzTx4v3RSv17mgg2pf+Uc3ZmWt/Pl2j1nHkxFO5Vol/otmuHYFS/3eHLe+LujwRVWCGk9gUfKigd+vvBLmUvmy0/p5v4xeGSfeHA4+rRx6OXtvWuM+zrh5Azl3Zbd0KCYuTMJzOCzSsKyJUITkdhMfjoW3AaJmBmJvYIGJeeCfyFCHQEXGEfyztQzH3FecJoVwkpAEyPvOqQOAZQe57qxUj/AGD10arXph/T9/mVo4jo3m/v+qjyvKdfZ0ga7ws7oGeeMd6iAYixqg0Vp0eExY/QxfbdnHdWIO0y2xP8ptd7iAtEW3859RYUcmvNNn/1axbPqFox9K/f90BKfvte3CpI9bqE5154JpNo6cJrZAXyCp4p61I1EAAAAAElFTkSuQmCC);
      background-size: 21px 22px;
    }
  }

  &__continue-shopping {
    margin-top: var(--space-small);

    a {
      &:last-child {
        display: none;
      }
    }
  }

  @include media-desktop {
    &--full-page {
      #{$S} {
        &__price-container {
          padding: 2em;
          background-color: var(--background-color);
          align-self: start;
          position: sticky;
          top: calc(var(--fixed-content-height) + 1em);
        }

        &__subtotal {
          background: transparent;
        }

        &__continue-shopping {
          margin-top: var(--space-medium);

          a {
            &:first-child {
              display: none;
            }

            &:last-child {
              display: block;
            }
          }
        }

        &__go-to-cart {
          display: none;
        }
      }
    }
  }
}

@keyframes slide-up {
  0% {
    transform: translate3d(0, 1000px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.cart__price-container {
  grid-area: b;
}
</style>
