import Provider from '@/provider'
import {
  Attribute,
  CartAttributesUpdateInput,
  CartDiscountCodeApplyInput,
  CartDiscountCodeRemoveInput,
  CartLineItemsRemoveInput,
  CartLineItemsUpdateInput,
  CartLineItemUpdateInput,
} from '@/provider/type'
import { CartLineItemInput } from '@/provider/type'
import Analytics from '@/services/analytics'
import CacheService from '@/services/cache'
import { CartLineItem, InputWithTimeStamp, NullOrType } from '@/types'
import {
  CACHE_KEY_CART_ID,
  COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY,
  DISCOUNT_MESSAGE_LINE_ITEM_ATTRIBUTE_KEY,
  DOMAIN_ATTRIBUTE_KEY,
  PRICE_LINE_ITEM_ATTRIBUT_KEY,
  USER_TRAITS_ATTRIBUTE_KEY,
  UTMS_ATTRIBUTE_KEY,
  FIRST_PAGE_KEY,
  COOKIE_KEY_ATTRIBUTE_UTM_PREFIX,
  ONE_YEAR_IN_SECONDS,
} from '@/utils'

import {
  extractProductSKUFromHandle,
  getTruncatedVariantSKU,
  getTruncatedVariantSKUWithProductHandlle,
} from '@/utils/product'
import { isOnServer } from '@/utils/ssr'
import { defineStore } from 'pinia'
import { CookieManager } from '@/services/cookie'
import useSettingStore from '../setting'

export interface CartState {
  cart: NullOrType<any>
  localCartLineItems: any[]
  lastUpdateTimeStamp: number
  recoveryCartId: NullOrType<string>
  shouldRecoveryCartIdBaseOnEmailKlaviyo: boolean
  recoverCartIdByEmailMessage: string
}

const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    cart: null,
    localCartLineItems: [],
    lastUpdateTimeStamp: 0,
    recoveryCartId: null,
    shouldRecoveryCartIdBaseOnEmailKlaviyo: false,
    recoverCartIdByEmailMessage: '',
  }),
  getters: {
    subTotal: (state) => {
      return state.cart?.estimatedCost?.subtotalAmount?.amount || 0
    },
    totalLineItemsPrice: (state) => {
      let total = 0
      state.cart?.lines.forEach((item) => {
        total +=
          item.quantity *
          (item.merchandise?.compareAtPriceV2?.amount ||
            item.merchandise?.priceV2?.amount ||
            0)
      })
      return total
    },
    totalDiscount(): number {
      return this.totalLineItemsPrice - this.subTotal
    },
    totalCartItems: (state) => {
      return state.localCartLineItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      )
    },
    discountCodeCurrentApply(state) {
      return state?.cart?.discountCodes.length
        ? state.cart.discountCodes[0].code
        : ''
    },
  },
  actions: {
    async getCurrentCart() {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      let cartId = ''
      if (this.recoveryCartId) {
        cartId = this.recoveryCartId
        this.recoveryCartId = null
      } else if (this.cart) {
        cartId = this.cart.id
      } else {
        const cookier = new CookieManager()
        const cartIdFromCookie = cookier.getCookie(CACHE_KEY_CART_ID)
        if (cartIdFromCookie) {
          cartId = cartIdFromCookie
        } else {
          const cartIdFromCache = await CacheService.instance?.get(
            CACHE_KEY_CART_ID
          )
          if (cartIdFromCache && cartIdFromCache.value) {
            carttId = cartIdFromCache.value
            const cookier = new CookieManager()

            const href = new URL(window.location.href)
            const currentDomain = href.hostname
            cookier.setCookie(CACHE_KEY_CART_ID, cartId, {
              maxage: ONE_YEAR_IN_SECONDS,
              domain: '.' + currentDomain,
              path: '/',
            })
          }
        }
      }
      if (cartId) {
        return provider.getCart(cartId)
      }
      return null
    },
    async createCart(input: any) {
      if (isOnServer) return
      input.attributes = await fillUpCartAttributes(input.attributes)
      const provider = await Provider.getInstance()
      return provider.createCart(input)
    },

    async saveCartAndLines(cart: NullOrType<Cart>) {
      if (isOnServer) return
      this.saveCart(JSON.parse(JSON.stringify(cart)))
      this.localCartLineItems = JSON.parse(JSON.stringify(cart?.lines || []))
      this.localCartLineItems = this.localCartLineItems?.map(fillUpLineItem)
    },

    async saveCart(cart: NullOrType<Cart>) {
      if (isOnServer) return
      this.cart = cart
      if (!this.cart) return
      this.cart.lines = this.cart.lines?.map(fillUpLineItem)
      const cartId = cart?.id
      if (cartId) {
        // cache cart forever
        CacheService.instance?.set(CACHE_KEY_CART_ID, cartId, -1)

        // cache Cart id in cookie fallback brower doesn't support IndexDB and sync cart online store with shopify theme
        const href = new URL(window.location.href)
        const currentDomain = href.hostname
        const cookier = new CookieManager()
        cookier.setCookie(CACHE_KEY_CART_ID, cartId, {
          maxage: ONE_YEAR_IN_SECONDS,
          domain: '.' + currentDomain,
          path: '/',
        })
      } else {
        CacheService.instance?.delete(CACHE_KEY_CART_ID)
      }
    },
    async saveCartWithoutUpdateLineItemsQuantity(cart: NullOrType<Cart>) {
      if (isOnServer) return
      if (!cart) return
      this.saveCart(cart)

      // use the lineItems from the new cart but reserve the quantity from the old cart
      let lines = JSON.parse(JSON.stringify(cart.lines || []))
      lines = lines.map((line: CartLineItem) => {
        const oldLine = this.localCartLineItems.find(
          (item) => item.id === line.id
        )
        if (oldLine) {
          line.quantity = oldLine.quantity
        }
        line = fillUpLineItem(line)
        return line
      })
      this.localCartLineItems = lines
    },
    async addItemToCart(input: CartLineItemsAddInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartLinesAdd(input)
    },
    async removeItemFromCart(input: CartLineItemsRemoveInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartLinesRemove(input)
    },

    async updateItemsInCart(input: CartLineItemsUpdateInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartLinesUpdate(input)
    },

    async updateCartAttributes(input: CartAttributesUpdateInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartAttributesUpdate(input)
    },

    async cartDiscountCodeApply(discountCode: string) {
      if (isOnServer) return
      const cartId = this.cart?.id
      if (!cartId) return
      const provider = await Provider.getInstance()
      const input: CartDiscountCodeApplyInput = {
        cartId,
        discountCodes: [discountCode],
      }
      const cart = await provider.cartDiscountCodesUpdate(input)
      this.saveCartAndLines(cart)
      return cart
    },

    async cartDiscountCodeRemove() {
      if (isOnServer) return
      const cartId = this.cart?.id
      if (!cartId) return
      const provider = await Provider.getInstance()
      const input: CartDiscountCodeRemoveInput = {
        cartId,
        discountCodes: [],
      }
      const cart = await provider.cartDiscountCodesUpdate(input)
      this.saveCartAndLines(cart)
    },

    async updateCartBuyerIdentity(input: {
      buyerIdentity: MailingAddressInput
      cartId: string
    }) {
      if (isOnServer) return
      if (!input.cartId || !input.buyerIdentity) return
      const provider = await Provider.getInstance()
      return provider.cartBuyerIdentityUpdate(input)
    },

    async updateCartEmail(input: { cartId: string; email: string }) {
      if (isOnServer) return
      if (!input.cartId || !input.email) return
      const provider = await Provider.getInstance()
      return provider.cartEmailUpdate(input)
    },

    async addToCart(line: CartLineItemInput) {
      if (isOnServer) return
      if (!line.merchandiseId || !line.quantity) return

      if (!this.cart) {
        const cart = await this.createCart({
          lines: [line],
        })
        this.saveCartAndLines(cart)
        return cart
      }

      const cart = await this.addItemToCart({
        cartId: this.cart.id,
        lines: [line],
      })
      this.saveCartAndLines(cart)
      return cart
    },

    async removeFromCart(
      input: InputWithTimeStamp<{
        lineId: string
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart || !input.lineId) return
      const cart = await this.removeItemFromCart({
        cartId: this.cart.id,
        lineIds: [input.lineId],
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp !== input.timeStamp) return null
      this.saveCartAndLines(cart)
      return cart
    },

    async updateCartLine(
      input: InputWithTimeStamp<{
        id: string
        quantity: number
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart || !input.id) return
      const cart = await this.updateItemsInCart({
        cartId: this.cart.id,
        lines: [
          {
            id: input.id,
            quantity: input.quantity,
          },
        ],
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCartAndLines(cart)
        return cart
      }
      return null
    },

    async updateAttributeCartLine(
      input: InputWithTimeStamp<{
        id: string
        attributes: { key: string; value: string }[]
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart || !input.id) return
      const cart = await this.updateItemsInCart({
        cartId: this.cart.id,
        lines: [
          {
            id: input.id,
            attributes: input.attributes,
          },
        ],
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCartAndLines(cart)
        return cart
      }
      return null
    },

    async updateCartLines(
      input: InputWithTimeStamp<{
        lines: CartLineItemUpdateInput[]
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart) return
      const cart = await this.updateItemsInCart({
        cartId: this.cart.id,
        lines: input.lines,
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCartWithoutUpdateLineItemsQuantity(cart)
        return cart
      }
      return null
    },
  },
})

export async function fillUpCartAttributes(attributesInput?: Attribute[]) {
  const attributes: Attribute[] = []
  if (attributesInput) {
    attributesInput.forEach((attribute) => {
      attributes.push({
        key: attribute.key,
        value: attribute.value,
      })
    })
  }
  const domainAttributeIndex = attributes.findIndex(
    (attribute) => attribute.key == DOMAIN_ATTRIBUTE_KEY
  )
  if (domainAttributeIndex === -1) {
    attributes.push({
      key: DOMAIN_ATTRIBUTE_KEY,
      value: window.location.origin,
    })
  }

  const userTraitsAttribute = Analytics.getUserTraits()

  if (userTraitsAttribute) {
    const userTraitsAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == USER_TRAITS_ATTRIBUTE_KEY
    )
    if (userTraitsAttributeIndex > -1) {
      attributes.splice(userTraitsAttributeIndex, 1)
    }
    attributes.push({
      key: USER_TRAITS_ATTRIBUTE_KEY,
      value: JSON.stringify(userTraitsAttribute),
    })
  }

  const firstPageFromCookie = Analytics.getCookieFirstPage()
  if (firstPageFromCookie) {
    const firstPageAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == FIRST_PAGE_KEY
    )
    if (firstPageAttributeIndex > -1) {
      attributes.splice(firstPageAttributeIndex, 1)
    }
    attributes.push({
      key: FIRST_PAGE_KEY,
      value: firstPageFromCookie,
    })
  }

  const currentUserUtms = await Analytics.getCurrentUTMs()
  if (currentUserUtms) {
    const utmsAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == UTMS_ATTRIBUTE_KEY
    )
    if (utmsAttributeIndex > -1) {
      attributes.splice(utmsAttributeIndex, 1)
    }
    attributes.push({
      key: UTMS_ATTRIBUTE_KEY,
      value: JSON.stringify(currentUserUtms),
    })
  }

  const cookieUserUtms = Analytics.getCookieCurrentUTMs()
  if (cookieUserUtms) {
    const UTM_PARAM_PREFIX = 'utm_'
    Object.keys(cookieUserUtms).forEach((key) => {
      const utmAttributeKey = `${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}${UTM_PARAM_PREFIX}${key}`
      const utmsAttributeIndex = attributes.findIndex(
        (attribute) => attribute.key == utmAttributeKey
      )
      if (utmsAttributeIndex > -1) {
        attributes.splice(utmsAttributeIndex, 1)
      }
      attributes.push({
        key: utmAttributeKey,
        value: cookieUserUtms[key],
      })
    })
  }
  return attributes
}

function fillUpLineItem(line: CartLineItem) {
  const priceAttribute = line.attributes.find(
    (elm) => elm.key == PRICE_LINE_ITEM_ATTRIBUT_KEY
  )
  const isPriceAttributeValid =
    priceAttribute && priceAttribute.value && !isNaN(+priceAttribute.value)
  const priceFromAttribute = isPriceAttributeValid
    ? +priceAttribute.value / 100
    : 0

  const compareAtPriceAttribute = line.attributes.find(
    (elm) => elm.key == COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY
  )
  const isCompareAtPriceAttributeValid =
    compareAtPriceAttribute &&
    compareAtPriceAttribute.value &&
    !isNaN(+compareAtPriceAttribute.value)
  const compareAtPriceFromAttribute = isCompareAtPriceAttributeValid
    ? +compareAtPriceAttribute.value / 100
    : 0

  const discountMessageAttribute = line.attributes.find(
    (elm) => elm.key == DISCOUNT_MESSAGE_LINE_ITEM_ATTRIBUTE_KEY
  )

  line.price = isPriceAttributeValid
    ? priceFromAttribute
    : line.variant?.price || 0
  line.compareAtPrice = isCompareAtPriceAttributeValid
    ? compareAtPriceFromAttribute
    : line.variant?.compareAtPrice || 0
  if (discountMessageAttribute) {
    line.discountMessage = discountMessageAttribute.value
  }

  if (line.variant && line.variant.sku) {
    const settingStore = useSettingStore()
    let hasProductHandle = false
    if (line.variant.product?.handle) {
      const skuFromHandle = extractProductSKUFromHandle(
        line.variant.product?.handle
      )
      hasProductHandle =
        settingStore.shop?.googleProductSKUsFromHandle
          ?.split(',')
          .includes(skuFromHandle) || false
    }
    line.variant.sku = hasProductHandle
      ? getTruncatedVariantSKU(line.variant.sku)
      : getTruncatedVariantSKUWithProductHandlle(
          line.variant.sku,
          line.variant.product?.handle
        )
  }

  // Analytics error if variant is null to track this bug
  if (!line.variant) {
    try {
      throw line.variant.price
    } catch (err: any) {
      Analytics.error(err)
    }
  }

  return line
}

export default useCartStore
