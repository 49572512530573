<template>
  <div class="cart__recover-by-email-wrapper">
    <p class="cart__recover-by-email-note">
      Enter Email to Recover Your Saved Cart
    </p>
    <form @submit.prevent="onSubmit" ref="form">
      <div class="cart__recover-by-email">
        <sib-form-item
          :style="`flex:1; margin-bottom:0`"
          label="Email"
          input-id="recover-form__email"
          :value="email"
          is-required
          :validator="isEmail"
          ref="itemEmail"
          error-message="Email is invalid"
        >
          <sib-input
            id="recover-form__email"
            size="sm"
            placeholder="Email to recover cart"
            v-model="email"
            name="email"
          ></sib-input>
        </sib-form-item>

        <sib-button
          class="sib-button--width-auto"
          role="primary"
          :loading="loading"
          :disabled="!email"
        >
          Recover
        </sib-button>

        <p
          v-show="cartStore.recoverCartIdByEmailMessage"
          class="recover-cart__message"
          :class="{
            'recover-cart__message--success':
              cartStore.recoverCartIdByEmailMessage.includes('Success'),
            'recover-cart__message--error':
              !cartStore.recoverCartIdByEmailMessage.includes('Success'),
          }"
        >
          {{ cartStore.recoverCartIdByEmailMessage }}
        </p>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import useUserStore from '@/store/user'
import LocationService, { UserLocation } from '@/services/location'
import {
  CACHE_KEY_CART_ID,
  MIXPANEL_DESTINATION,
  ONE_YEAR_IN_SECONDS,
} from '@/utils/constants'
import { CookieManager } from '@/services/cookie'
import Analytics from '@/services/analytics'
import useCartStore from '@/store/cart'

import { ref, nextTick } from 'vue'
import Logger from '@/services/log'
import FormItem from '@/ui-kits/FormItem.vue'
import {
  FORM_ITEM_INVALID_SELECTOR,
  HEADER_HEIGHT,
  destinationIntegrations,
  isEmail,
  removePropertiesStartWithPrefix,
  EVENT_TRACKING_CART_RECOVERY,
} from '@/utils'
import SibFormItem from '@/ui-kits/FormItem.vue'

const emit = defineEmits<{
  (e: 'cart-recovery'): void
}>()

const userStore = useUserStore()
const cartStore = useCartStore()

const loading = ref(false)
const email = ref('')
const itemEmail = ref<typeof FormItem | null>(null)
const form = ref<HTMLFormElement | null>(null)

async function identifyUserByEmailRecoverCart(
  emailUser: string,
  ip: string,
  user: any
) {
  const email = emailUser.toLowerCase()
  const userTraits = Analytics.getUserTraits()

  const listEmail = userTraits?.listEmail || []
  if (!listEmail?.length || !listEmail.includes(email)) {
    listEmail.push(email)
  }
  let userEmail = { email, listEmail }

  const listIp = userTraits?.listIp || []
  let userIpLocation = {
    ip: ip,
    listIp: listIp.slice(-5),
  }

  const getProductUserAgent = () => {
    const userAgentString = navigator.userAgent
    return userAgentString.split(')', 1)[0] + ')'
  }
  const currentUserAgent = getProductUserAgent()
  let listUserAgent = userTraits?.listUserAgent || []
  if (!listUserAgent.length || !listUserAgent.includes(currentUserAgent)) {
    listUserAgent.push(currentUserAgent)
  }
  listUserAgent = listUserAgent.slice(-5)
  let userAgents = { userAgent: currentUserAgent, listUserAgent }

  let traits = {}
  const userIdIdentified = user.id

  if (user?.traits) {
    traits = removePropertiesStartWithPrefix(user.traits, '$')
    // we use field cartId so remove field cart_id in traits
    delete traits?.cart_id
  }
  if (user?.traits?.listEmail?.length) {
    let listEmailMerged = user.traits.listEmail || []
    if (listEmailMerged.length) {
      listEmailMerged = listEmailMerged.map((email: string) =>
        email.toLowerCase()
      )
    }
    listEmail.forEach((email: string) => {
      if (!listEmailMerged.includes(email)) {
        listEmailMerged.push(email)
      }
    })
    listEmailMerged = listEmailMerged.slice(-5) // get the 5 newest email
    userEmail = {
      email,
      listEmail: listEmailMerged,
    }
  }

  // merge list ip
  if (user?.traits?.listIp?.length) {
    const listIpMerged = user.traits.listIp || []
    listIp.forEach((ip: string) => {
      if (!listIpMerged.includes(ip)) {
        listIpMerged.push(ip)
      }
    })
    userIpLocation = {
      ip: ip,
      listIp: listIpMerged.slice(-5),
    }
  }

  // merge list user agent
  if (user?.traits?.listUserAgent?.length) {
    const listUserAgentMerged = user.traits.listUserAgent || []
    listUserAgent.forEach((userAgent: string) => {
      if (!listUserAgentMerged.includes(userAgent)) {
        listUserAgentMerged.push(userAgent)
      }
    })
    userAgents = {
      userAgent: currentUserAgent,
      listUserAgent: listUserAgentMerged.slice(-5),
    }
  }

  const cart = { cartId: user?.traits?.cart_id }

  Analytics.identify(userIdIdentified, {
    ...traits,
    ...userEmail,
    ...userIpLocation,
    ...userAgents,
    ...cart,
  })
}

async function recoverCartByEmail() {
  LocationService.onLocationReady(async (location: UserLocation | null) => {
    if (location?.ip && email.value) {
      try {
        const getProductUserAgent = () => {
          const userAgentString = navigator.userAgent
          return userAgentString.split(')', 1)[0] + ')'
        }
        const currentUserAgent = getProductUserAgent()

        loading.value = true
        const user = await userStore.getUserIdentified(
          location.ip,
          email.value,
          currentUserAgent
        )
        loading.value = false

        if (user?.traits?.cart_id) {
          const cookier = new CookieManager()
          const href = new URL(window.location.href)
          const currentDomain = href.hostname
          cookier.setCookie(CACHE_KEY_CART_ID, user?.traits?.cart_id, {
            maxage: ONE_YEAR_IN_SECONDS,
            domain: '.' + currentDomain,
            path: '/',
          })
          cartStore.recoverCartIdByEmailMessage = `Success! Your saved cart has been restored.`
          emit('cart-recovery')
          identifyUserByEmailRecoverCart(email.value, location.ip, user)
        } else {
          cartStore.recoverCartIdByEmailMessage = `Unfortunately, we were unable to recover a cart with the email you've entered.`
        }
      } catch (error: any) {
        loading.value = false
        cartStore.recoverCartIdByEmailMessage = `Unfortunately, we were unable to recover a cart with the email you've entered.`
      }
    }
  })
}

async function onSubmit() {
  cartStore.recoverCartIdByEmailMessage = ''
  const isEmailValid = itemEmail.value?.validate()
  const isValid = isEmailValid
  if (!isValid) {
    await nextTick()
    const firstInvalidElement: HTMLElement | undefined | null =
      form.value?.querySelector(FORM_ITEM_INVALID_SELECTOR)
    if (firstInvalidElement) {
      window.scrollTo({
        top:
          window.scrollY +
          firstInvalidElement.getBoundingClientRect().top -
          HEADER_HEIGHT,
        behavior: 'smooth',
      })
    }
    return
  }
  if (!form.value) return

  try {
    await recoverCartByEmail()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error: any) {
    Logger.error('Error when recover cart by email', error)
    Analytics.error(error)
  }
  Analytics.track(
    EVENT_TRACKING_CART_RECOVERY,
    {
      email: email.value,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}
</script>
<style lang="scss">
.cart__recover-by-email-wrapper {
  .cart__recover-by-email {
    display: grid;
    grid-template-columns: 1fr 110px;
    gap: 0 1rem;
    .sib-form-item__label {
      display: none;
    }
    .sib-input {
      --input-padding: var(--input-md-padding);
      height: unset;
    }
    .sib-button {
      width: 110px;
    }
  }
  .recover-cart__message {
    margin-top: 3px;
    font-size: 12.25px;
    &--success {
      color: var(--color-primary);
    }
    &--error {
      color: var(--color-secondary);
    }
  }
  .cart__recover-by-email-note {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .cart__recover-by-email .sib-form-item--invalid ~ .recover-cart__message {
    display: none;
  }
}
</style>
